/* eslint-disable max-len */
import VueRouter from 'vue-router';

// Import components here
const Home = () => import('@/Client/Page/Home');
const Login = () => import('@/Client/Page/Login');
const Profile = () => import('@/Client/Page/System/Profile');
const DataOption = () => import('@/Client/Page/System/DataOption');
const Users = () => import('@/Client/Page/System/Users');
const ShowcaseUsers = () => import('@/Client/Page/System/ShowcaseUsers');
const AccessRequests = () => import('@/Client/Page/System/AccessRequests');
import CompanyLogin from '@/Client/Component/Security/CompanyLogin.vue';

const CreateTelevision = () => import('@/Client/Page/Create/CreateTelevision');
const CreateMovie = () => import('@/Client/Page/Create/CreateMovie');
const CreateInvoice = () => import('@/Client/Page/Create/CreateInvoice');
const CreateInvoiceItem = () => import('@/Client/Page/Create/CreateInvoiceItem');
const CreateCompany = () => import('@/Client/Page/Create/CreateCompany');
const CreateContact = () => import('@/Client/Page/Create/CreateContact');
const CreateSystem = () => import('@/Client/Page/Create/CreateSystem');
const CreateCategory = () => import('@/Client/Page/Create/CreateCategory');
const CreateActorDirector = () => import('@/Client/Page/Create/CreateActorDirector');
const CreateAlbum = () => import('@/Client/Page/Create/CreateAlbum');
const CreateShort = () => import('@/Client/Page/Create/CreateShort');
const CreateOtherVideoItem = () => import('@/Client/Page/Create/CreateOtherVideoItem');
const CreateOtherAudioItem = () => import('@/Client/Page/Create/CreateOtherAudioItem');
const CreateOtherAudioGroup = () => import('@/Client/Page/Create/CreateOtherAudioGroup');

const ReadLicenseOrder = () => import('@/Client/Page/Read/ReadLicenseOrder');
const ReadLicenseOrderSummary = () => import('@/Client/Page/Read/ReadLicenseOrderSummary');
const ReadStudio = () => import('@/Client/Page/Read/ReadStudio');
const ReadDistributor = () => import('@/Client/Page/Read/ReadDistributor');
const ReadOtherAudioDistributor = () => import('@/Client/Page/Read/ReadOtherAudioDistributor');
const Index = () => import('@/Client/Page/DistributorLanding/Index');
const ReadAirline = () => import('@/Client/Page/Read/ReadAirline');
const ReadContentServiceProvider = () => import('@/Client/Page/Read/ReadContentServiceProvider');
const ReadSystemVendor = () => import('@/Client/Page/Read/ReadSystemVendor');
const ReadContact = () => import('@/Client/Page/Read/ReadContact');
const ReadSystem = () => import('@/Client/Page/Read/ReadSystem');
const ReadCategory = () => import('@/Client/Page/Read/ReadCategory');
const ReadActorDirector = () => import('@/Client/Page/Read/ReadActorDirector');
const ReadUser = () => import('@/Client/Page/Read/ReadUser');
const ReadAlbum = () => import('@/Client/Page/Read/ReadAlbum');
const ReadTelevision = () => import('@/Client/Page/Read/ReadTelevision');
const ReadMovie = () => import('@/Client/Page/Read/ReadMovie');
const ReadRecordLabel = () => import('@/Client/Page/Read/ReadRecordLabel');
const ReadOtherVideoItem = () => import('@/Client/Page/Read/ReadOtherVideoItem');
const ReadOtherAudioGroup = () => import('@/Client/Page/Read/ReadOtherAudioGroup');
const ReadOtherAudioItem = () => import('@/Client/Page/Read/ReadOtherAudioItem');

const UpdateMovie = () => import('@/Client/Page/Update/UpdateMovie');
const UpdateTelevision = () => import('@/Client/Page/Update/UpdateTelevision');
const UpdateEpisode = () => import('@/Client/Page/Update/UpdateEpisode');
const UpdateSeason = () => import('@/Client/Page/Update/UpdateSeason');
const UpdateContact = () => import('@/Client/Page/Update/UpdateContact');
const UpdateAirline = () => import('@/Client/Page/Update/UpdateAirline');
const UpdateContentServiceProvider = () => import('@/Client/Page/Update/UpdateContentServiceProvider');
const UpdateDistributor = () => import('@/Client/Page/Update/UpdateDistributor');
const UpdateStudio = () => import('@/Client/Page/Update/UpdateStudio');
const UpdateSystemVendor = () => import('@/Client/Page/Update/UpdateSystemVendor');
const UpdateActorDirector = () => import('@/Client/Page/Update/UpdateActorDirector');
const UpdateSystem = () => import('@/Client/Page/Update/UpdateSystem');
const UpdateCategory = () => import('@/Client/Page/Update/UpdateCategory');
const UpdateTrack = () => import('@/Client/Page/Update/UpdateTrack');
const UpdateAlbum = () => import('@/Client/Page/Update/UpdateAlbum');
const UpdateRecordLabel = () => import('@/Client/Page/Update/UpdateRecordLabel');
const UpdateSelectionItems = () => import('@/Client/Page/Update/UpdateSelectionItems');
const UpdateShort = () => import('@/Client/Page/Update/UpdateShort');
const UpdateVideoMediaConfigTemplate = () => import('@/Client/Page/Update/UpdateVideoMediaConfigTemplate');
const UpdateAudioMediaConfigTemplate = () => import('@/Client/Page/Update/UpdateAudioMediaConfigTemplate');
const UpdateOtherVideoItem = () => import('@/Client/Page/Update/UpdateOtherVideoItem');
const UpdateOtherAudioItem = () => import('@/Client/Page/Update/UpdateOtherAudioItem');
const UpdateOtherAudioGroup = () => import('@/Client/Page/Update/UpdateOtherAudioGroup');
const UpdateOtherAudioGroupItem = () => import('@/Client/Page/Update/UpdateOtherAudioGroupItem');

const ListLicenseOrder = () => import('@/Client/Page/List/ListLicenseOrder');
const ListMaterialOrder = () => import('@/Client/Page/List/ListMaterialOrder');
const ListAvailability = () => import('@/Client/Page/List/ListAvailability');
const ListMovies = () => import('@/Client/Page/List/ListMovies');
const ListTelevision = () => import('@/Client/Page/List/ListTelevision');
const ListContents = () => import('@/Client/Page/List/ListContents');
const ListInvoices = () => import('@/Client/Page/List/ListInvoices');
const ListCompanies = () => import('@/Client/Page/List/ListCompanies');
const ListContacts = () => import('@/Client/Page/List/ListContacts');
const ListActors = () => import('@/Client/Page/List/ListActors');
const ListDirectors = () => import('@/Client/Page/List/ListDirectors');
const ListCategories = () => import('@/Client/Page/List/ListCategories');
const ListSystem = () => import('@/Client/Page/List/ListSystem');
const StatusSheet = () => import('@/Client/Page/List/StatusSheet');
const ListAlbums = () => import('@/Client/Page/List/ListAlbums');
const ListOtherVideoItems = () => import('@/Client/Page/List/ListOtherVideoItems');
const ListLanguages = () => import('@/Client/Page/List/ListLanguages');
const ListOtherAudioGroups = () => import('@/Client/Page/List/ListOtherAudioGroups');
const ListOtherAudioItems = () => import('@/Client/Page/List/ListOtherAudioItems');
const LoadSheet = () => import('@/Client/Page/List/LoadSheet');
const ListCommonStateChangeReasons = () => import('@/Client/Page/List/ListCommonStateChangeReasons');

const ViewingStatistics = () => import('@/Client/Page/Report/ViewingStatistics');
const ReportStudioPerformance = () => import('@/Client/Page/Report/ReportStudioPerformance');

const PageNotFound = () => import('@/Client/Page/PageNotFound');
const UpdateLicenseOrder = () => import('@/Client/Page/Update/UpdateLicenseOrder');
const ReadMaterialOrder = () => import('@/Client/Page/Read/ReadMaterialOrder');
const UpdateMaterialOrder = () => import('@/Client/Page/Update/UpdateMaterialOrder');
const Unauthorised = () => import('@/Client/Page/Unauthorised');
const UpdateSelectionGroup = () => import('@/Client/Page/Update/UpdateSelectionGroup');
const ReadLab = () => import('@/Client/Page/Read/ReadLab');
const UpdateLab = () => import('@/Client/Page/Update/UpdateLab');
const CreateSelectionGroup = () => import('@/Client/Page/Create/CreateSelectionGroup');
const ReadSeason = () => import('@/Client/Page/Read/ReadSeason');
const ReadEpisode = () => import('@/Client/Page/Read/ReadEpisode');
const ReadShort = () => import('@/Client/Page/Read/ReadShort');
const CreateLicenseOrder = () => import('@/Client/Page/Create/CreateLicenseOrder');
const UpdateSelectionMetadata = () => import('@/Client/Page/Update/UpdateSelectionMetadata');
const UpdateSelectionCategories = () => import('@/Client/Page/Update/UpdateSelectionCategories');
const ListTags = () => import('@/Client/Page/List/ListTags');
const CreateTag = () => import('@/Client/Page/Create/CreateTag');
const ProfileDistributor = () => import('@/Client/Page/Profile/ProfileDistributor');
const ListAirlines = () => import('@/Client/Page/List/ListAirlines');
const ListCycles = () => import('@/Client/Page/List/ListCycles.vue');
const ListDeals = () => import('@/Client/Page/List/ListDeals.vue');
const ReadDeal = () => import('@/Client/Page/Read/ReadDeal.vue');
const CreateDeal = () => import('@/Client/Page/Create/CreateDeal.vue');
const UpdateDeal = () => import('@/Client/Page/Update/UpdateDeal.vue');
const ListGenres = () => import('@/Client/Page/List/ListGenres.vue');
const ReadGenre = () => import('@/Client/Page/Read/ReadGenre.vue');
const CreateGenre = () => import('@/Client/Page/Create/CreateGenre.vue');
const UpdateGenre = () => import('@/Client/Page/Update/UpdateGenre.vue');
const CreateTerritory = () => import('@/Client/Page/Create/CreateTerritory.vue');
const ListTerritories = () => import('@/Client/Page/List/ListTerritories.vue');
const ReadTerritory = () => import('@/Client/Page/Read/Territory/ReadTerritory.vue');
const ReadArea = () => import('@/Client/Page/Read/Territory/ReadArea.vue');
const ReadContinent = () => import('@/Client/Page/Read/Territory/ReadContinent.vue');
const ReadCountry = () => import('@/Client/Page/Read/Territory/ReadCountry.vue');
const ReadProvince = () => import('@/Client/Page/Read/Territory/ReadProvince.vue');
const UpdateArea = () => import('@/Client/Page/Update/Territory/UpdateArea.vue');
const UpdateContinent = () => import('@/Client/Page/Update/Territory/UpdateContinent.vue');
const UpdateCountry = () => import('@/Client/Page/Update/Territory/UpdateCountry.vue');
const UpdateProvince = () => import('@/Client/Page/Update/Territory/UpdateProvince.vue');
const ReadLanguage = () => import('@/Client/Page/Read/ReadLanguage.vue');
const CreateLanguage = () => import('@/Client/Page/Create/CreateLanguage.vue');
const UpdateLanguage = () => import('@/Client/Page/Update/UpdateLanguage.vue');
const ListVersionTypes = () => import('@/Client/Page/List/ListVersionTypes.vue');
const ReadVersionType = () => import('@/Client/Page/Read/ReadVersionType.vue');
const CreateVersionType = () => import('@/Client/Page/Create/CreateVersionType.vue');
const UpdateVersionType = () => import('@/Client/Page/Update/UpdateVersionType.vue');
const UpdateSelectionCategoryOrdering = () => import('@/Client/Page/Update/Selection/UpdateSelectionCategoryOrdering.vue');
const ListRatings = () => import('@/Client/Page/List/ListRatings.vue');
const CreateRating = () => import('@/Client/Page/Create/CreateRating.vue');
const UpdateRating = () => import('@/Client/Page/Update/UpdateRating.vue');
const ReadRating = () => import('@/Client/Page/Read/ReadRating.vue');
const AirlineClientPortal = () => import('@/Client/Page/List/AirlineClientPortal.vue');
const CreateExport = () => import('../Page/Create/CreateExport.vue');
const ReadExport = () => import('../Page/Read/ReadExport.vue');
const ListExports = () => import('../Page/List/ListExports.vue');
const UpdateExport = () => import('../Page/Update/UpdateExport.vue');
const ReadTrailer = () => import('@/Client/Page/Read/ReadTrailer.vue');
const UpdateTrailer = () => import('@/Client/Page/Update/UpdateTrailer');
const ForgotPassword = () => import('@/Client/Page/ForgotPassword');
const ResetPassword = () => import('../Page/ResetPassword.vue');
const LoginAttempts = () => import('@/Client/Page/System/LoginAttempts.vue');
const CreateImport = () => import('@/Client/Page/Create/CreateImport');

// Build router here
const router = new VueRouter({
  mode: 'history',
  routes: [
    // Create
    {path: '/movie/new', name: 'createMovie', component: CreateMovie, meta: {permission: 'ROLE_CONTENT_MOVIE_RESOURCE_CREATE'}},
    {path: '/television/new', name: 'createTelevision', component: CreateTelevision, meta: {permission: 'ROLE_CONTENT_TELEVISION_RESOURCE_CREATE'}},
    {path: '/short/new', name: 'createShort', component: CreateShort, meta: {permission: 'ROLE_CONTENT_SHORT_RESOURCE_CREATE'}},
    {path: '/license-order/:id/invoice/new', name: 'createInvoiceFromLicenseOrder', component: CreateInvoice, meta: {permission: 'ROLE_INVOICE_RESOURCE_CREATE'}},
    {path: '/invoice/new', name: 'createInvoice', component: CreateInvoice, meta: {permission: 'ROLE_INVOICE_RESOURCE_CREATE'}},
    {path: '/invoice/:id', name: 'createInvoiceItem', component: CreateInvoiceItem, meta: {permission: 'ROLE_INVOICEITEM_RESOURCE_CREATE'}},
    {path: '/company/new', name: 'createCompany', component: CreateCompany},
    {path: '/contact/new', name: 'createContact', component: CreateContact},
    {path: '/actor-director/new', name: 'createActorDirector', component: CreateActorDirector, meta: {permission: 'ROLE_PERSON_RESOURCE_CREATE'}},
    {path: '/system/new', name: 'createSystem', component: CreateSystem, meta: {permission: 'ROLE_SYSTEM_RESOURCE_CREATE'}},
    {path: '/category/new', name: 'createCategory', component: CreateCategory, meta: {permission: 'ROLE_CATEGORY_RESOURCE_CREATE'}},
    {path: '/deal/new', name: 'createDeal', component: CreateDeal, meta: {permission: 'ROLE_DEAL_RESOURCE_CREATE'}},
    {path: '/genre/new', name: 'createGenre', component: CreateGenre, meta: {permission: 'ROLE_GENRE_RESOURCE_CREATE'}},
    {path: '/territory/new', name: 'createTerritory', component: CreateTerritory, meta: {permission: 'ROLE_TERRITORY_TERRITORY_RESOURCE_CREATE'}},

    {path: '/album/new', name: 'createAlbum', component: CreateAlbum, meta: {permission: 'ROLE_CONTENT_ALBUM_RESOURCE_CREATE'}},
    {path: '/selection/:id/order', name: 'createOrderWithSelection', component: CreateLicenseOrder, meta: {permission: 'ROLE_LICENSEORDER_RESOURCE_CREATE'}},
    {path: '/selection-group/new', name: 'createSelection', component: CreateSelectionGroup, meta: {permission: 'ROLE_SELECTIONGROUP_RESOURCE_CREATE'}},
    {path: '/tag/new', name: 'createTag', component: CreateTag, meta: {permission: 'ROLE_TAG_RESOURCE_CREATE'}},
    {path: '/language/new', name: 'createLanguage', component: CreateLanguage, meta: {permission: 'ROLE_LANGUAGE_RESOURCE_CREATE'}},
    {path: '/other-video/new', name: 'createOtherVideoItem', component: CreateOtherVideoItem, meta: {permission: 'ROLE_CONTENT_OTHER_VIDEO_ITEM_RESOURCE_CREATE'}},
    {path: '/version-type/new', name: 'createVersionType', component: CreateVersionType, meta: {permission: 'ROLE_VERSIONTYPE_RESOURCE_CREATE'}},
    {path: '/other-audio/new', name: 'createOtherAudioItem', component: CreateOtherAudioItem, meta: {permission: 'ROLE_CONTENT_OTHER_AUDIO_ITEM_RESOURCE_CREATE'}},
    {path: '/other-audio-group/new', name: 'createOtherAudioGroup', component: CreateOtherAudioGroup, meta: {permission: 'ROLE_CONTENT_OTHER_AUDIO_GROUP_RESOURCE_CREATE'}},
    {path: '/rating/new', name: 'createRating', component: CreateRating, meta: {permission: 'ROLE_RATING_RESOURCE_CREATE'}},
    {path: '/export/new', name: 'createExport', component: CreateExport, meta: {permission: 'ROLE_EXPORT_RESOURCE_CREATE'}},
    {path: '/import/new', name: 'createImport', component: CreateImport, meta: {permission: 'ROLE_IMPORT_RESOURCE_CREATE'}},

    // Read
    {path: '/movie/:id', name: 'readMovie', component: ReadMovie, meta: {permission: 'ROLE_CONTENT_MOVIE_RESOURCE_READ'}},
    {path: '/television/:id', name: 'readTelevision', component: ReadTelevision, meta: {permission: 'ROLE_CONTENT_TELEVISION_RESOURCE_READ'}},
    {path: '/season/:id', name: 'readSeason', component: ReadSeason, meta: {permission: 'ROLE_CONTENT_SEASON_RESOURCE_READ'}},
    {path: '/episode/:id', name: 'readEpisode', component: ReadEpisode, meta: {permission: 'ROLE_CONTENT_EPISODE_RESOURCE_READ'}},
    {path: '/short/:id', name: 'readShort', component: ReadShort, meta: {permission: 'ROLE_CONTENT_SHORT_RESOURCE_READ'}},
    {path: '/license-order/:id', name: 'readLicenseOrder', component: ReadLicenseOrder, meta: {permission: 'ROLE_LICENSEORDER_RESOURCE_READ'}},
    {path: '/order-summary/:id', name: 'readOrderSummary', component: ReadLicenseOrderSummary, meta: {permission: 'ROLE_LICENSEORDER_RESOURCE_READ'}},
    {path: '/material-order/:id', name: 'readMaterialOrder', component: ReadMaterialOrder, meta: {permission: 'ROLE_MATERIALORDER_RESOURCE_READ'}},
    {path: '/studio/:id', name: 'readStudio', component: ReadStudio, meta: {permission: 'ROLE_STUDIO_RESOURCE_READ'}},
    {path: '/airline/:id', name: 'readAirline', component: ReadAirline, meta: {
      permission: 'ROLE_AIRLINE_RESOURCE_READ',
      companyAuthorisation: true,
      companyId(route) {
        return route.params.id;
      },
    }},
    {path: '/distributor/:id', name: 'readDistributor', component: ReadDistributor, meta: {permission: 'ROLE_DISTRIBUTOR_RESOURCE_READ'}},
    {path: '/distributor/:id/landing', name: 'distributorLanding', component: Index, meta: {customPageLayout: true, fallbackTitle: false}}, // todo - probably sort out cleaner url?
    {path: '/other-audio-distributor/:id', name: 'readOtherAudioDistributor', component: ReadOtherAudioDistributor, meta: {permission: 'ROLE_OTHERAUDIODISTRIBUTOR_RESOURCE_READ'}},
    {path: '/content-service-provider/:id', name: 'readContentServiceProvider', component: ReadContentServiceProvider, meta: {permission: 'ROLE_CONTENTSERVICEPROVIDER_RESOURCE_READ'}},
    {path: '/system-vendor/:id', name: 'readSystemVendor', component: ReadSystemVendor, meta: {permission: 'ROLE_SYSTEMVENDOR_RESOURCE_READ'}},
    {path: '/record-label/:id', name: 'readRecordLabel', component: ReadRecordLabel, meta: {permission: 'ROLE_RECORDLABEL_RESOURCE_READ'}},
    {path: '/lab/:id', name: 'readLab', component: ReadLab, meta: {permission: 'ROLE_LAB_RESOURCE_READ'}},
    {path: '/contact/:id', name: 'readContact', component: ReadContact, meta: {permission: 'ROLE_ADMIN'}},
    {path: '/actor-director/:id', name: 'readActorDirector', component: ReadActorDirector, meta: {permission: 'ROLE_PERSON_RESOURCE_READ'}},
    {path: '/system/:id', name: 'readSystem', component: ReadSystem, meta: {permission: 'ROLE_SYSTEM_RESOURCE_READ'}},
    {path: '/category/:id', name: 'readCategory', component: ReadCategory, meta: {permission: 'ROLE_CATEGORY_RESOURCE_READ'}},
    {path: '/genre/:id', name: 'readGenre', component: ReadGenre, meta: {permission: 'ROLE_GENRE_RESOURCE_READ'}},
    {path: '/deal/:id', name: 'readDeal', component: ReadDeal, meta: {permission: 'ROLE_DEAL_RESOURCE_READ'}},
    {path: '/territory/:id', name: 'readTerritory', component: ReadTerritory, meta: {permission: 'ROLE_TERRITORY_TERRITORY_RESOURCE_READ'}},
    {path: '/area/:id', name: 'readArea', component: ReadArea, meta: {permission: 'ROLE_TERRITORY_AREA_RESOURCE_READ'}},
    {path: '/continent/:id', name: 'readContinent', component: ReadContinent, meta: {permission: 'ROLE_TERRITORY_CONTINENT_RESOURCE_READ'}},
    {path: '/country/:id', name: 'readCountry', component: ReadCountry, meta: {permission: 'ROLE_TERRITORY_COUNTRY_RESOURCE_READ'}},
    {path: '/province/:id', name: 'readProvince', component: ReadProvince, meta: {permission: 'ROLE_TERRITORY_PROVINCE_RESOURCE_READ'}},
    {path: '/language/:id', name: 'readLanguage', component: ReadLanguage, meta: {permission: 'ROLE_LANGUAGE_RESOURCE_READ'}},
    {path: '/user/:id', name: 'readUser', component: ReadUser, meta: {permission: 'ROLE_USER_RESOURCE_READ'}},
    {path: '/album/:id', name: 'readAlbum', component: ReadAlbum, meta: {permission: 'ROLE_CONTENT_ALBUM_RESOURCE_READ'}},
    {path: '/version-type/:id', name: 'readVersionType', component: ReadVersionType, meta: {permission: 'ROLE_VERSIONTYPE_RESOURCE_READ'}},
    {path: '/rating/:id', name: 'readRating', component: ReadRating, meta: {permission: 'ROLE_RATING_RESOURCE_READ'}},

    {path: '/other-video/:id', name: 'readOtherVideo', component: ReadOtherVideoItem, meta: {permission: 'ROLE_CONTENT_OTHER_VIDEO_ITEM_RESOURCE_READ'}},
    {path: '/other-audio-group/:id', name: 'readOtherAudioGroup', component: ReadOtherAudioGroup, meta: {permission: 'ROLE_CONTENT_OTHER_AUDIO_GROUP_RESOURCE_READ'}},
    {path: '/other-audio/:id', name: 'readOtherAudioItem', component: ReadOtherAudioItem, meta: {permission: 'ROLE_CONTENT_OTHER_AUDIO_ITEM_RESOURCE_READ'}},
    {path: '/export/:id', name: 'readExport', component: ReadExport, meta: {permission: 'ROLE_EXPORT_RESOURCE_READ'}},
    {path: '/trailer/:id', name: 'readTrailer', component: ReadTrailer, meta: {permission: 'ROLE_CONTENT_OTHER_VIDEO_ITEM_RESOURCE_READ'}},

    // Update
    {path: '/update-movie/:id', name: 'updateMovie', component: UpdateMovie, meta: {permission: 'ROLE_CONTENT_MOVIE_RESOURCE_UPDATE'}},
    {path: '/update-television/:id', name: 'updateTelevision', component: UpdateTelevision, meta: {permission: 'ROLE_CONTENT_TELEVISION_RESOURCE_UPDATE'}},
    {path: '/update-season/:id', name: 'updateSeason', component: UpdateSeason, meta: {permission: 'ROLE_CONTENT_SEASON_RESOURCE_UPDATE'}},
    {path: '/update-episode/:id', name: 'updateEpisode', component: UpdateEpisode, meta: {permission: 'ROLE_CONTENT_EPISODE_RESOURCE_UPDATE'}},
    {path: '/update-short/:id', name: 'updateShort', component: UpdateShort, meta: {permission: 'ROLE_CONTENT_SHORT_RESOURCE_UPDATE'}},
    {path: '/contact/:id/edit', name: 'updateContact', component: UpdateContact, meta: {permission: 'ROLE_ADMIN'}},
    {path: '/airline/:id/edit', name: 'updateAirline', component: UpdateAirline, meta: {permission: 'ROLE_AIRLINE_RESOURCE_UPDATE'}},
    {path: '/content-service-provider/:id/edit', name: 'updateContentServiceProvider', component: UpdateContentServiceProvider, meta: {permission: 'ROLE_CONTENTSERVICEPROVIDER_RESOURCE_UPDATE'}},
    {path: '/system-vendor/:id/edit', name: 'updateSystemVendor', component: UpdateSystemVendor, meta: {permission: 'ROLE_SYSTEMVENDOR_RESOURCE_UPDATE'}},
    {path: '/studio/:id/edit', name: 'updateStudio', component: UpdateStudio, meta: {permission: 'ROLE_STUDIO_RESOURCE_UPDATE'}},
    {path: '/distributor/:id/edit', name: 'updateDistributor', component: UpdateDistributor, meta: {permission: 'ROLE_DISTRIBUTOR_RESOURCE_UPDATE'}},
    {path: '/record-labels/:id/edit', name: 'updateRecordLabel', component: UpdateRecordLabel, meta: {permission: 'ROLE_RECORDLABEL_RESOURCE_UPDATE'}},
    {path: '/labs/:id/edit', name: 'updateLab', component: UpdateLab, meta: {permission: 'ROLE_LAB_RESOURCE_UPDATE'}},
    {path: '/actor-director/:id/edit', name: 'updateActorDirector', component: UpdateActorDirector, meta: {permission: 'ROLE_ADMIN'}},
    {path: '/system/:id/edit', name: 'updateSystem', component: UpdateSystem, meta: {permission: 'ROLE_SYSTEM_RESOURCE_UPDATE'}},
    {path: '/category/:id/edit', name: 'updateCategory', component: UpdateCategory, meta: {permission: 'ROLE_CATEGORY_RESOURCE_UPDATE'}},
    {path: '/genre/:id/edit', name: 'updateGenre', component: UpdateGenre, meta: {permission: 'ROLE_GENRE_RESOURCE_UPDATE'}},
    {path: '/deal/:id/edit', name: 'updateDeal', component: UpdateDeal, meta: {permission: 'ROLE_DEAL_RESOURCE_UPDATE'}},
    {path: '/area/:id/edit', name: 'updateArea', component: UpdateArea, meta: {permission: 'ROLE_TERRITORY_AREA_RESOURCE_UPDATE'}},
    {path: '/continent/:id/edit', name: 'updateContinent', component: UpdateContinent, meta: {permission: 'ROLE_TERRITORY_CONTINENT_RESOURCE_UPDATE'}},
    {path: '/country/:id/edit', name: 'updateCountry', component: UpdateCountry, meta: {permission: 'ROLE_TERRITORY_COUNTRY_RESOURCE_UPDATE'}},
    {path: '/province/:id/edit', name: 'updateProvince', component: UpdateProvince, meta: {permission: 'ROLE_TERRITORY_PROVINCE_RESOURCE_UPDATE'}},
    {path: '/album/:id/edit', name: 'updateAlbum', component: UpdateAlbum, meta: {permission: 'ROLE_CONTENT_ALBUM_RESOURCE_UPDATE'}},
    {path: '/album/:albumId/track/:trackId/edit', name: 'updateTrack', component: UpdateTrack, meta: {permission: 'ROLE_CONTENT_TRACK_RESOURCE_UPDATE'}},
    {path: '/selection/:id/items', name: 'updateSelectionItems', component: UpdateSelectionItems, meta: {permission: 'ROLE_SELECTIONITEM_RESOURCE_UPDATE'}},
    {path: '/license-order/:id/edit', name: 'updateLicenseOrder', component: UpdateLicenseOrder, meta: {permission: 'ROLE_LICENSEORDER_RESOURCE_UPDATE'}},
    {path: '/video-media-config-template/:id/edit', name: 'updateVideoMediaConfigTemplate', component: UpdateVideoMediaConfigTemplate, meta: {permission: 'ROLE_MEDIACONFIG_VIDEOMEDIACONFIGTEMPLATE_RESOURCE_UPDATE'}},
    {path: '/audio-media-config-template/:id/edit', name: 'updateAudioMediaConfigTemplate', component: UpdateAudioMediaConfigTemplate, meta: {permission: 'ROLE_MEDIACONFIG_AUDIOMEDIACONFIGTEMPLATE_RESOURCE_UPDATE'}},
    {path: '/material-order/:id/edit', name: 'updateMaterialOrder', component: UpdateMaterialOrder, meta: {permission: 'ROLE_MATERIALORDER_RESOURCE_UPDATE'}},
    {path: '/selection-group/:id/edit', name: 'updateSelectionGroup', component: UpdateSelectionGroup, meta: {permission: 'ROLE_SELECTIONGROUP_RESOURCE_UPDATE'}},
    {path: '/selection-metadata', name: 'updateSelectionMetadata', component: UpdateSelectionMetadata, meta: {permission: 'ROLE_SELECTIONGROUP_RESOURCE_UPDATE'}},
    {path: '/selection-categories', name: 'updateSelectionCategories', component: UpdateSelectionCategories, meta: {permission: 'ROLE_SELECTIONGROUP_RESOURCE_UPDATE'}},
    {path: '/language/:id/edit', name: 'updateLanguage', component: UpdateLanguage, meta: {permission: 'ROLE_LANGUAGE_RESOURCE_UPDATE'}},
    {path: '/version-type/:id', name: 'updateVersionType', component: UpdateVersionType, meta: {permission: 'ROLE_VERSIONTYPE_RESOURCE_UPDATE'}},
    {path: '/rating/:id', name: 'updateRating', component: UpdateRating, meta: {permission: 'ROLE_RATING_RESOURCE_UPDATE'}},
    {path: '/update-selection-category-ordering', name: 'updateSelectionCategoryOrdering', component: UpdateSelectionCategoryOrdering, meta: {permission: 'ROLE_SELECTIONGROUP_RESOURCE_UPDATE'}},
    {path: '/update-other-video-item/:id', name: 'updateOtherVideoItem', component: UpdateOtherVideoItem, meta: {permission: 'ROLE_CONTENT_OTHER_VIDEO_ITEM_RESOURCE_UPDATE'}},
    {path: '/other-audio-item/:id', name: 'updateOtherAudioItem', component: UpdateOtherAudioItem, meta: {permission: 'ROLE_CONTENT_OTHER_AUDIO_ITEM_RESOURCE_UPDATE'}},
    {path: '/other-audio-group/:id', name: 'updateOtherAudioGroup', component: UpdateOtherAudioGroup, meta: {permission: 'ROLE_CONTENT_OTHER_AUDIO_GROUP_RESOURCE_UPDATE'}},
    {path: '/other-audio-group/:otherAudioGroupId/item/:otherAudioGroupItemId', name: 'updateOtherAudioGroupItem', component: UpdateOtherAudioGroupItem, meta: {permission: 'ROLE_CONTENT_OTHER_AUDIO_GROUP_ITEM_RESOURCE_UPDATE'}},
    {path: '/export/:id/edit', name: 'updateExport', component: UpdateExport, meta: {permission: 'ROLE_EXPORT_RESOURCE_UPDATE'}},
    {path: '/trailer/:id/edit', name: 'updateTrailer', component: UpdateTrailer, meta: {permission: 'ROLE_CONTENT_MOVIE_RESOURCE_UPDATE'}},

    // List
    {path: '/license-order', name: 'licenseOrderList', component: ListLicenseOrder, meta: {permission: 'ROLE_LICENSEORDER_RESOURCE_LIST'}},
    {path: '/material-order', name: 'materialOrderList', component: ListMaterialOrder, meta: {permission: 'ROLE_MATERIALORDER_RESOURCE_LIST'}},
    {path: '/availability', name: 'availabilityList', component: ListAvailability, meta: {permission: 'ROLE_AVAILABILITY_RESOURCE_LIST'}},
    {path: '/contents', name: 'contentsList', component: ListContents, meta: {permission: 'ROLE_CONTENT_RESOURCE_LIST'}},
    {path: '/movies', name: 'movieList', component: ListMovies, meta: {permission: 'ROLE_CONTENT_MOVIE_RESOURCE_LIST'}},
    {path: '/television', name: 'televisionList', component: ListTelevision, meta: {permission: 'ROLE_CONTENT_TELEVISION_RESOURCE_LIST'}},
    {path: '/invoice', name: 'invoiceList', component: ListInvoices, meta: {permission: 'ROLE_INVOICE_RESOURCE_LIST'}},
    {path: '/companies', name: 'companyList', component: ListCompanies, meta: {permission: 'ROLE_COMPANY_RESOURCE_LIST'}},
    {path: '/my-airlines', name: 'cspAirlineList', component: ListAirlines, meta: {permission: 'ROLE_CSP_USER'}},
    {path: '/cycles', name: 'cyclesList', component: ListCycles, meta: {permission: 'ROLE_ADMIN'}},
    {path: '/contacts', name: 'contactList', component: ListContacts},
    {path: '/actors', name: 'actorList', component: ListActors, meta: {permission: 'ROLE_PERSON_RESOURCE_LIST'}},
    {path: '/directors', name: 'directorList', component: ListDirectors, meta: {permission: 'ROLE_PERSON_RESOURCE_LIST'}},
    {path: '/systems', name: 'systemList', component: ListSystem, meta: {permission: 'ROLE_SYSTEM_RESOURCE_LIST'}},
    {path: '/deals', name: 'dealList', component: ListDeals, meta: {permission: 'ROLE_DEAL_RESOURCE_LIST'}},
    {path: '/categories', name: 'categoryList', component: ListCategories, meta: {permission: 'ROLE_CATEGORY_RESOURCE_LIST'}},
    {path: '/genres', name: 'genreList', component: ListGenres, meta: {permission: 'ROLE_GENRE_RESOURCE_LIST'}},
    {path: '/ratings', name: 'ratingList', component: ListRatings, meta: {permission: 'ROLE_RATING_RESOURCE_LIST'}},
    {path: '/territories', name: 'territoryList', component: ListTerritories, meta: {permission: 'ROLE_TERRITORY_TERRITORY_RESOURCE_LIST'}},
    {path: '/version-types', name: 'versionTypeList', component: ListVersionTypes, meta: {permission: 'ROLE_VERSIONTYPE_RESOURCE_LIST'}},

    {path: '/tags', name: 'tagList', component: ListTags, meta: {permission: 'ROLE_TAG_RESOURCE_LIST'}},
    {path: '/status-sheet', name: 'statusSheet', component: StatusSheet, meta: {permission: 'ROLE_SELECTION_USER'}},
    {path: '/albums', name: 'albumList', component: ListAlbums, meta: {permission: 'ROLE_CONTENT_ALBUM_RESOURCE_LIST'}},
    {path: '/languages', name: 'languageList', component: ListLanguages, meta: {permission: 'ROLE_LANGUAGE_RESOURCE_LIST'}},
    {path: '/other-videos', name: 'otherVideoItemList', component: ListOtherVideoItems, meta: {permission: 'ROLE_CONTENT_OTHER_VIDEO_ITEM_RESOURCE_LIST'}},
    {path: '/other-audio', name: 'otherAudioItemList', component: ListOtherAudioItems, meta: {permission: 'ROLE_CONTENT_OTHER_AUDIO_ITEM_RESOURCE_LIST'}},
    {path: '/other-audio-groups', name: 'otherAudioGroupList', component: ListOtherAudioGroups, meta: {permission: 'ROLE_CONTENT_OTHER_AUDIO_GROUP_RESOURCE_LIST'}},
    {path: '/exports', name: 'exportList', component: ListExports, meta: {permission: 'ROLE_EXPORT_RESOURCE_LIST'}},
    {path: '/load-sheet', name: 'loadSheet', component: LoadSheet, meta: {permission: 'ROLE_ORDERS_READ_PARTIAL'}},
    {path: '/common-state-change-reasons', name: 'commonStateChangeReasons', component: ListCommonStateChangeReasons, meta: {permission: 'ROLE_TEXTVALUE_COMMONSTATECHANGEREASON_RESOURCE_LIST'}},

    {path: '/airline-client-portal', name: 'airlineClientPortal', component: AirlineClientPortal, meta: {permission: 'ROLE_AIRLINE_CLIENT_PORTAL_USER'}},

    // Profile
    {path: '/profile/distributor/:id?', name: 'distributorProfile', component: ProfileDistributor, meta: {permission: 'ROLE_DISTRIBUTOR_RESOURCE_READ'}},

    // Report
    {path: '/viewing-statistics/', name: 'viewingStatistics', component: ViewingStatistics},
    {path: '/report/studio-performance', name: 'studioPerformanceReport', component: ReportStudioPerformance},

    // Redirects
    {path: '/api/airlines/:id', redirect: {name: 'readAirline'}},
    {path: '/api/areas/:id', redirect: {name: 'readArea'}},
    {path: '/api/continents/:id', redirect: {name: 'readContinent'}},
    {path: '/api/countries/:id', redirect: {name: 'readCountry'}},
    {path: '/api/provinces/:id', redirect: {name: 'readProvince'}},
    {path: '/api/distributors/:id', redirect: {name: 'readDistributor'}},
    {path: '/api/other_audio_distributors/:id', redirect: {name: 'readOtherAudioDistributor'}},
    {path: '/api/content_service_providers/:id', redirect: {name: 'readContentServiceProvider'}},
    {path: '/api/record_labels/:id', redirect: {name: 'readRecordLabel'}},
    {path: '/api/system_vendors/:id', redirect: {name: 'readSystemVendor'}},
    {path: '/api/labs/:id', redirect: {name: 'readLab'}},
    {path: '/api/studios/:id', redirect: {name: 'readStudio'}},
    {path: '/api/movies/:id', redirect: {name: 'readMovie'}},
    {path: '/api/televisions/:id', redirect: {name: 'readTelevision'}},
    {path: '/api/seasons/:id', redirect: {name: 'readSeason'}},
    {path: '/api/episodes/:id', redirect: {name: 'readEpisode'}},
    {path: '/api/other_video_items/:id', redirect: {name: 'readOtherVideo'}},
    {path: '/api/users/:id', redirect: {name: 'readUser'}},
    {path: '/api/deals/:id', redirect: {name: 'readDeal'}},
    {path: '/api/genres/:id', redirect: {name: 'readGenre'}},
    {path: '/api/languages/:id', redirect: {name: 'readLanguage'}},
    {path: '/api/version_types/:id', redirect: {name: 'readVersionType'}},
    {path: '/api/ratings/:id', redirect: {name: 'readRating'}},
    {path: '/api/movies/:id/edit', redirect: {name: 'updateMovie'}},
    {path: '/api/televisions/:id/edit', redirect: {name: 'updateTelevision'}},
    {path: '/api/seasons/:id/edit', redirect: {name: 'updateSeason'}},
    {path: '/api/episodes/:id/edit', redirect: {name: 'updateEpisode'}},
    {path: '/api/shorts/:id/edit', redirect: {name: 'updateShort'}},
    {path: '/api/albums/:id/edit', redirect: {name: 'updateAlbum'}},
    {path: '/api/tracks/:id/edit', redirect: {name: 'updateTrack'}},
    {path: '/api/albums/:id', redirect: {name: 'readAlbum'}},
    {path: '/api/shorts/:id', redirect: {name: 'readShort'}},
    {path: '/api/other-audio-groups/:id', redirect: {name: 'readOtherAudioGroup'}},
    {path: '/api/category/:id', redirect: {name: 'readCategory'}},
    {path: '/api/material_orders/:orderId', redirect: {name: 'orderItem'}},
    {path: '/api/video_media_config_templates/:id', redirect: {name: 'updateVideoMediaConfigTemplate'}},
    {path: '/api/audio_media_config_templates/:id', redirect: {name: 'updateAudioMediaConfigTemplate'}},
    {path: '/api/other_video_items/:id/edit', redirect: {name: 'updateOtherVideoItem'}},
    {path: '/api/exports/:id', redirect: {name: 'readExport'}},
    {path: '/api/trailers/:id', redirect: {name: 'readTrailer'}},
    {path: '/api/trailers/:id/edit', redirect: {name: 'updateTrailer'}},

    // System
    {path: '/', name: 'home', component: Home},
    {path: '/profile', name: 'profile', component: Profile},
    {path: '/data-options', name: 'dataOptions', component: DataOption},
    {path: '/users', name: 'users', component: Users},
    {path: '/showcase-users', name: 'showcaseUsers', component: ShowcaseUsers, meta: {permission: 'ROLE_DISTRIBUTOR_USER'}},
    {path: '/login-attempts', name: 'loginAttempts', component: LoginAttempts, meta: {permission: 'ROLE_LOGINATTEMPT_RESOURCE_LIST'}},
    {path: '/access-requests', name: 'accessRequests', component: AccessRequests, meta: {permission: 'ROLE_COMPANY_USER_REQUEST_RESOURCE_LIST'}},
    {path: '/unauthorised', name: 'unauthorised', component: Unauthorised},
    {path: '/login/:company', name: 'companyLogin', component: CompanyLogin, meta: {noAuth: true, fallbackTitle: false}},
    {path: '/login', name: 'login', component: Login, meta: {noAuth: true}},
    {path: '/request-access', name: 'requestAccess', meta: {noAuth: true}},
    {path: '/forgot-password', name: 'forgotPassword', component: ForgotPassword, meta: {noAuth: true}},
    {path: '/reset-password', name: 'resetPassword', component: ResetPassword, meta: {noAuth: true}},
    {path: '*', name: 'pageNotFound', component: PageNotFound, meta: {noAuth: true}},
  ],
});

export default router;
